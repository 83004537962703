import {
  GENERATE_PDF_EXAMS,
  GET_EXAMS,
  GET_TEMPLATES,
  GET_EXAM_GROUPS,
} from "services/api";
import AppContext from "services/context";

import validationSchema from "./validationSchema";

import patientSectionValidationSchema from "components/PatientFormSection/validationSchema";

import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button";
import Input from "components/Input";
import FormPageTemplate from "components/LYT_FormPage";
import PatientFormSection, {
  patientSectionInitialValues,
} from "components/PatientFormSection";
import PremiumBadge from "components/PremiumBadge";
import Select from "components/Select";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useErrors from "services/hooks/useErrors";
import useGeneratePdfApiResponse from "services/hooks/useGeneratePdfApiResponse";
import useLGPDTerms from "services/hooks/useLGPDTerms";
import usePatientParams from "services/hooks/usePatientParams";
import { PrescriptionItem } from "./styles";

const initialValues = {
    patientCNS: "",
    patientDob: "",
    patientAddressUf: "BA",
    patientAddressCity: "Água Fria",
    examsList: [],
    prescriptionItems: [
      {
        name: "",
        dosage: "",
        quantity: "",
        value: "",
      },
    ],
  },
  patientSectionRequiredFields = {
    name: true,
    motherName: false,
    dob: false,
    sex: false,
    cns: false,
    address: {},
  },
  patientSectionVisibleFields = {
    motherName: false,
    sex: false,
    address: {
      uf: true,
      city: true,
    },
  };

export default () => {
  const [generatePdfExams, { loading, error, data }] =
      useMutation(GENERATE_PDF_EXAMS),
    { data: examsData } = useQuery(GET_EXAMS),
    { data: examGroupsData } = useQuery(GET_EXAM_GROUPS),
    { data: templatesData } = useQuery(GET_TEMPLATES, {
      variables: { documentName: "exame" },
    }),
    [examOptions, setExamOptions] = useState([]),
    [examGroupOptions, setExamGroupOptions] = useState([]),
    formik = useFormik({
      initialValues: usePatientParams({
        ...patientSectionInitialValues,
        ...initialValues,
      }),
      initialTouched: {},
      validationSchema: validationSchema.concat(
        patientSectionValidationSchema(patientSectionRequiredFields)
      ),
      onSubmit: (values) => {
        setLoading(true);
        const variables = {
          ...values,
        };
        generatePdfExams({
          variables,
        });
      },
    }),
    { setLoading, user } = useContext(AppContext),
    navigate = useNavigate();

  useEffect(() => {
    if (examsData?.exams?.length) {
      setExamOptions(
        examsData.exams.map((e) => ({
          label: e.name,
          value: e.name,
        }))
      );
    }
  }, [examsData]);

  useEffect(() => {
    if (examGroupsData?.examGroups?.length) {
      setExamGroupOptions(
        [
          ...examGroupsData.examGroups.map((e) => ({
            ...e,
            value: e.name,
            name: (
              <>
                {e.name}{" "}
                {!user && e.login && <PremiumBadge type="account-small" />}
              </>
            ),
          })),
          {
            name: !user?.isPremium ? (
              <>
                Crie um grupo de Exame <PremiumBadge type="small" />
              </>
            ) : (
              <>Crie um grupo de Exame</>
            ),
            url: user?.isPremium ? "/meus-exames" : "/premium#planos",
          },
        ].map((e) => ({
          ...e,
          label: e.name,
          value: e.value,
          isDisabled:
            (!user && e.login) || (user && !user.isPremium && e.premium),
          exams: e.exams,
        }))
      );
    }
  }, [examGroupsData, user]);

  useEffect(() => {
    // Inicializando preferências de usuário
    if (user?.isPremium) {
      formik.setFieldValue("reason", user.preferences?.labTestReason);
      formik.setFieldValue("patientAddressCity", user.preferences?.city);
      formik.setFieldValue(
        "patientAddressUf",
        user.preferences?.state?.uf.split(".")[1]
      );
      formik.setFieldValue("template", user.preferences?.labTestTemplate?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGeneratePdfApiResponse("generatePdfSolicitacaoExames", {
    data,
    error,
    loading,
  });
  useErrors(error);
  useLGPDTerms(formik.values);

  return (
    <FormPageTemplate
      metaTags={{
        title: "Ferramenta de Documentos Médicos Online - APAC",
        description:
          "🚀 Agora ficou muito mais fácil Gerar sua APAC com essa ferramenta online, gratuita, de fácil uso e acesso! Agora não tem mais desculpa para deixar documento para o coleguinha do próximo plantão!",
        imagePath: "apac.jpg",
      }}
    >
      <main>
        <header>
          <h1>
            <p>Gerar</p>Prescrição Médica{" "}
            <Link to="/">[Ver outros Documentos]</Link>
            <p>
              Crie prescrições de forma fácil e com liberdade. Em breve uma IA
              para te ajudar nessa jornada!
            </p>
          </h1>
          <p className="warning">
            Página em construção, muito provavelmente não funcionará pois ainda
            está em andamento
          </p>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <section>
            <header>
              <h2>Dados do Paciente</h2>
            </header>
            <PatientFormSection
              formik={formik}
              requiredFields={patientSectionRequiredFields}
              visibleFields={patientSectionVisibleFields}
            />
          </section>
          <section>
            <header>
              <h2>Itens de Prescrição</h2>
            </header>
            <section>
              <Select
                placeholder="Grupo de Prescrição"
                name="examsGroup"
                formik={formik}
                options={examGroupOptions}
                description="Selecione um grupo para carregar um pacote salvo de itens de prescrição"
                onChange={(option) => {
                  if (option.url) {
                    navigate(option.url);
                    return;
                  }
                  formik.setFieldValue(
                    "examsList",
                    Array.from(
                      new Set([
                        ...formik.values.examsList,
                        ...option.exams.map((e) => e.name),
                      ])
                    )
                  );
                  formik.setFieldValue("reason", option.value);
                }}
                isClearable
              />
            </section>
            <section>
              {formik.values.prescriptionItems.map((item, index) => (
                <PrescriptionItem key={index}>
                  <Input
                    name={`prescriptionItemDocsIA.${index}`}
                    placeholder="Em breve mais facilidade para você"
                    label="Docs IA"
                    disabled
                    formik={formik}
                  />

                  <Select
                    name={`prescriptionItems.${index}.drug`}
                    placeholder="Nome do Medicamento"
                    formik={formik}
                    creatable
                    options={[
                      {
                        label: "Nome do Medicamento",
                        value: "Nome do Medicamento",
                        isDisabled: true,
                      },
                    ]}
                  />
                  <Input
                    name={`prescriptionItems.${index}.concentration`}
                    placeholder="Concentração"
                    formik={formik}
                  />
                  <Input
                    name={`prescriptionItems.${index}.quantity`}
                    placeholder="Quantidade"
                    formik={formik}
                  />
                  <Input
                    multiline
                    name={`prescriptionItems.${index}.dosage`}
                    placeholder="Posologia"
                    formik={formik}
                  />
                </PrescriptionItem>
              ))}

              <Button
                type="button"
                onClick={() =>
                  formik.setFieldValue("prescriptionItems", [
                    ...formik.values.prescriptionItems,
                    {},
                  ])
                }
              >
                Adicionar Item
              </Button>
            </section>
          </section>
          <section>
            <header>
              <h2>Estilo do Documento</h2>
              <Select
                name="template"
                placeholder="Template de documento"
                formik={formik}
                options={templatesData?.templates.map((t) => ({
                  label: t.description,
                  value: t.name,
                }))}
                required
              />
            </header>
          </section>
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
          <Button type="submit">Enviar</Button>
        </form>
      </main>
    </FormPageTemplate>
  );
};
