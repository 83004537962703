import styled from "styled-components";

export const PrescriptionItem = styled.div`
  padding: 0.5rem;
  border-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
`;
